import { FC, useEffect, useState } from 'react';
import block from 'utils/bem-css-module';
import { getShowByColor } from 'utils/map';
import { findURLParam } from 'utils/urls';

import { MapModal } from 'components/MapModal';

import { Coords } from 'types/common';
import { MapPoint, PrintType, Show } from 'types/map';
import { Point } from 'types/point';

import { MapScanQr } from './map-scan-qr';
import { YandexMap } from './yandex-map';

import style from './map.scss';

const b = block(style);


type Props = {
	show: Show;
	className?: string;
	onLoad?: () => void;
	defaultCoords?: Coords;
}

export const Map: FC<Props> = ({
	show = Show.all,
	className,
	onLoad,
	defaultCoords,
}) => {
	const [localShow, setLocalShow] = useState(show);
	const [isMapModalOpen, setIsMapModalOpen] = useState(false);
	const [point, setPoint] = useState<Point | undefined>();
	const [mapPoint, setMapPoint] = useState<MapPoint | undefined>();
	const [localPrintType, setLocalPrintType] = useState(PrintType.all);

	const setColor = (color: 'bw' | 'colorful'): void => {
		const show = getShowByColor(color, localShow);
		if (show !== localShow) {
			setLocalShow(show);
		}
	};

	useEffect(() => {
		const color = findURLParam('color');
		if (color === 'bw' || color === 'colorful') {
			setColor(color);
		}
	}, []);

	const afterCLose = () => {
		setMapPoint(undefined);
		setPoint(undefined);
	};
	
	const onFilter = (newShow: Show, newPrintType: PrintType) => {
		setLocalShow(newShow);
		setLocalPrintType(newPrintType);
	};

	return (
		<div className={b('container').mix(className)}>
			<MapModal
				isOpen={isMapModalOpen}
				closeOpen={() => setIsMapModalOpen(false)}
				point={point}
				show={localShow}
				isStaff={isStaff}
				mapPoint={mapPoint}
				afterCLose={afterCLose}
			/>
			<YandexMap
				setPoint={setPoint}
				setMapPoint={setMapPoint}
				show={localShow}
				printType={localPrintType}
				setIsMapModalOpen={setIsMapModalOpen}
				onLoad={onLoad}
				defaultCoords={defaultCoords}
				onFilterSave={onFilter}
			/>
			<MapScanQr />
		</div>
	);
};

export default Map;
