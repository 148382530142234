import { FC, useState } from 'react';
import { Button as MapButton } from '@pbe/react-yandex-maps';
import block from 'utils/bem-css-module';

import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { ModalButtons, ModalContent, ModalHeader, ModalPortal } from 'components/Modal';
import { Radio } from 'components/Radio';
import { YANDEX_METRIC_ID } from 'constants/common';

import { PrintType, Show } from 'types/map';

import style from './map-filter.scss';

const b = block(style);


type Props = {
	show: Show;
	onSave: (show: Show, printType: PrintType) => void;
	printType: PrintType;
}

const ID = 'map-filter';

export const MapFilter: FC<Props> = ({
	show,
	printType,
	onSave,
}) => {
	const [localShow, setLocalShow] = useState(show);
	const [localPrintType, setLocalPrintType] = useState(printType);
	const [isOpen, setIsOpen] = useState(false);

	const onShowClick = (newShow: Show) => () => {
		setLocalShow(newShow);
	};
	
	const onPrintTypeClick = (localPrintType: PrintType) => () => {
		setLocalPrintType(localPrintType);
	};
	
	const onSaveClick = () => {
		onSave(localShow, localPrintType);
		setIsOpen(false);
	};

	const onReset = () => {
		setLocalShow(show);
		setLocalPrintType(printType);
		onSave(show, printType);
	};
	
	const onFilterClick = () => {
		ym(YANDEX_METRIC_ID,'reachGoal','click-map-filter');
		setIsOpen(true);
	};

	return (
		<>
			<MapButton
				onClick={onFilterClick}
				options={{
					size: 'large',
					float: 'right',
				}}
				data={{
					image: '/static/img/filter.svg',
					content: 'Фильтры',
					title:'Фильтры',
				}}
				state={{
					enabled: true,
					selected: false,
				}}
			>
				Фильтры
			</MapButton>
			<ModalPortal isOpen={isOpen} id={ID} withMobileStretch={true}>
				<ModalHeader id={ID} isOpen={isOpen} close={() => setIsOpen(false)}>Фильтры</ModalHeader>
				<ModalContent size='sm' className={b('content')} id={ID}>
					<fieldset>
						<Heading level={2} size='m' className={b('heading')}>Доступная услуга:</Heading>
						<Radio
							onChange={onShowClick(Show.all)}
							checked={localShow === Show.all}
							name='show'
							id={`${ID}-service-all`}
							disabled={!isOpen}
						>
							Любая
						</Radio>
						<Radio
							onChange={onShowClick(Show.bwPrinters)}
							checked={localShow === Show.bwPrinters}
							name='show'
							id={`${ID}-service-bw`}
							disabled={!isOpen}
						>
							Ч/Б Печать
						</Radio>
						<Radio
							onChange={onShowClick(Show.colorfulPrinters)}
							checked={localShow === Show.colorfulPrinters}
							name='show'
							id={`${ID}-service-colorful`}
							disabled={!isOpen}
						>
							Цветная печать
						</Radio>
						<Radio
							onChange={onShowClick(Show.scanners)}
							checked={localShow === Show.scanners}
							name='show'
							id={`${ID}-service-copy`}
							disabled={!isOpen}
						>
							Сканирование и копирование
						</Radio>
					</fieldset>
					<fieldset>
						<Heading level={2} size='m' className={b('heading')}>Способ печати:</Heading>
						<Radio
							onChange={onPrintTypeClick(PrintType.all)}
							checked={localPrintType === PrintType.all}
							name='print-type'
							id={`${ID}-way-all`}
							disabled={!isOpen}
						>
							Любой
						</Radio>
						<Radio
							onChange={onPrintTypeClick(PrintType.automatic)}
							checked={localPrintType === PrintType.automatic}
							name='print-type'
							id={`${ID}-way-auto`}
							disabled={!isOpen}
						>
							Через автомат печати
						</Radio>
						<Radio
							onChange={onPrintTypeClick(PrintType.ab)}
							checked={localPrintType === PrintType.ab}
							name='print-type'
							id={`${ID}-way-ab`}
							disabled={!isOpen}
						>
							Дистанционная печать
						</Radio>
					</fieldset>
				</ModalContent>
				<ModalButtons>
					<Button
						size='m'
						buttonType='success'
						onClick={onSaveClick}
						disabled={!isOpen}
					>
						Применить
					</Button>
					<Button
						size='m'
						buttonType='default'
						onClick={onReset}
						disabled={!isOpen}
					>
						Сбросить
					</Button>
				</ModalButtons>
			</ModalPortal>
		</>
	);
};
