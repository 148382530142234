import { ComponentProps, FC } from 'react';
import { QRProvider } from 'context/qr-context';

import Map from './map';


export const MapWithQr: FC<ComponentProps<typeof Map>> = (props) => {
	return (
		<QRProvider>
			<Map {...props} />
		</QRProvider>
	);
};
export default MapWithQr;
