import { ChangeEvent, FC, HTMLAttributes, KeyboardEvent, useEffect, useRef, useState } from 'react';
import block from 'utils/bem-css-module';

import style from './code-input.scss';


const b = block(style);

type Props = {
	onFinish: (code: string) => void;
}

export const CodeInput: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	onFinish,
	className,
	...rest
}) => {
	const [code, setCode] = useState<(number | '')[]>(['', '', '', '']);
	const codeInputRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!codeInputRef.current) {
			return;
		}
		const inputs = codeInputRef.current.childNodes as NodeListOf<HTMLInputElement>;
		inputs[0].focus();
	}, []);

	useEffect(() => {
		if (!code.includes('')) {
			onFinish(code.reduce((result: string, value) => result + value.toString(), ''));
		}
	}, [code]);

	const onKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
		if (!codeInputRef.current) {
			return;
		}
		const inputs = codeInputRef.current.childNodes as NodeListOf<HTMLInputElement>;
		const { key } = e;
		const newCode = [...code];
		switch (key) {
			case 'ArrowLeft':
			case 'ArrowUp':
				index > 0 && inputs[index -1].focus();
				e.preventDefault();
				break;

			case 'ArrowRight':
			case 'ArrowDown':
				index !== code.length - 1 && inputs[index + 1].focus();
				e.preventDefault();
				break;
			case 'Backspace':
				if (newCode[index] !== '') {
					newCode[index] = '';
				} else {
					newCode[index - 1] = '';
				}
				setCode(newCode);
				if (index > 0) {
					inputs[index - 1].focus();
				}
				break;
			case 'e':
				e.preventDefault();
				e.stopPropagation();
				break;
		}
	};

	const onChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
		const key = e.target.value;
		if (!codeInputRef.current || isNaN(parseInt(key)) || key.length === 0) {
			return;
		}
		const inputs = codeInputRef.current.childNodes as NodeListOf<HTMLInputElement>;
		const newCode = [...code];
		let focusIndex = index;
		key.split('').forEach((digit, i) => {
			if (i + index < code.length) {
				newCode[i + index] = parseInt(digit);
				focusIndex = Math.min(i + index + 1, code.length - 1);
			}
		});
		setCode(newCode);
		inputs[focusIndex].focus();
	};
	

	return (
		<div className={b().mix(className)} ref={codeInputRef} {...rest}>
			<input
				type='number'
				min='0'
				max='9'
				key='number-code-0'
				role='input'
				value={code[0]}
				onKeyDown={onKeyDown(0)}
				onChange={onChange(0)}
			/>
			<input
				type='number'
				min='0'
				max='9'
				key='number-code-1'
				role='input'
				value={code[1]}
				onKeyDown={onKeyDown(1)}
				onChange={onChange(1)}
			/>
			<input
				type='number'
				min='0'
				max='9'
				key='number-code-2'
				role='input'
				value={code[2]}
				onKeyDown={onKeyDown(2)}
				onChange={onChange(2)}
			/>
			<input
				type='number'
				min='0'
				max='9'
				key='number-code-3'
				role='input'
				value={code[3]}
				onKeyDown={onKeyDown(3)}
				onChange={onChange(3)}
			/>
		</div>
	);
};
