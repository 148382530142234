import { FC, HTMLAttributes } from 'react';
import { QrScanner } from 'react-qrcode-scanner';
import block from 'utils/bem-css-module';
import { addQrParamsForTracking, isValidQR } from 'utils/urls';

import style from './qr-code-reader.scss';
const b = block(style);


type Props = {
	isOpen: boolean;
}

export const QrCodeReader: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	isOpen,
	className,
	...rest
}) => {
	const onReadQR = (result) => {
		if (isValidQR(result)) {
			window.location.href = addQrParamsForTracking(result);
		}
	};

	return (
		<div className={b().mix(className)}>
			<div key='top-left' className={b('qr-corner', { top: true, left: true })} />
			<div key='top-right' className={b('qr-corner', { top: true, right: true })} />
			<div key='bottom-left' className={b('qr-corner', { bottom: true, left: true })} />
			<div key='bottom-right' className={b('qr-corner', { bottom: true, right: true })} />
			{isOpen ? (
				<QrScanner
					onScan={onReadQR}
					facingMode='environment'
					constraints={null}
					flipHorizontally={true}
					className={b('reader')}
					delay={800} // delay between each scan
					resolution={600} // canvas resolution
					showViewFinder={false}
					aspectRatio='1:1'
					{...rest}
				/>
			) : (
				<div className={b('reader')} />
			)}
		</div>
	);
};
