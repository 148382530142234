import { ComponentProps, FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import block from 'utils/bem-css-module';
import { scrollToTop } from 'utils/element';

import { MobileQrBlock } from './mobile-qr-block';

import style from './mobile-qr-block.scss';

const b = block(style);


export const MobileQrBlockFullScreen:FC<ComponentProps<typeof MobileQrBlock>> = ({
	className,
	isQRReaderOpen,
	...rest
}) => {

	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (isQRReaderOpen) {
			scrollToTop();
			ref.current?.scrollTo(0, 0);
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
	}, [isQRReaderOpen]);
	return (
		createPortal(
			<div className={b('qr', { open: isQRReaderOpen }).mix(className)} ref={ref}>
				<MobileQrBlock {...rest} isQRReaderOpen={isQRReaderOpen} />
			</div>
			, 
			document.body,
		)
	);
};
