import { MapFeatures, MapPoint, PrintType, Show, StatusShow } from 'types/map';

/**
 * Returns if it's possible right now to do an operation by show
 * @param show
 * @param statusShow
 */
export function isActiveByShow(show: Show, statusShow: StatusShow) {
	switch(show) {
		case Show.bwPrinters: {
			return statusShow.bw_printers;
		}
		case Show.colorfulPrinters: {
			return statusShow.colorful_printers;
		}
		case Show.printers: {
			return statusShow.bw_printers || statusShow.colorful_printers;
		}
		case Show.scanners: {
			return statusShow.scanner;
		}
		case Show.bwCopy: {
			return statusShow.scanner && statusShow.bw_printers;
		}
		case Show.colorfulCopy: {
			return statusShow.scanner && statusShow.colorful_printers;
		}
		case Show.copy: {
			return statusShow.scanner && (statusShow.bw_printers || statusShow.colorful_printers);
		}
		case Show.all: {
			return statusShow.scanner || statusShow.bw_printers || statusShow.colorful_printers;
		}
	}
}

/**
 * Returns if a service exist by show
 * @param show
 * @param statusShow
 */
export function isExistByShow(show: Show, statusShow: StatusShow) {
	switch(show) {
		case Show.bwPrinters: {
			return statusShow.bw_printers !== undefined;
		}
		case Show.colorfulPrinters: {
			return statusShow.colorful_printers !== undefined;
		}
		case Show.printers: {
			return (statusShow.bw_printers !== undefined) || (statusShow.colorful_printers !== undefined);
		}
		case Show.scanners: {
			return statusShow.scanner !== undefined;
		}
		case Show.bwCopy: {
			return (statusShow.scanner !== undefined) && (statusShow.bw_printers !== undefined);
		}
		case Show.colorfulCopy: {
			return (statusShow.scanner !== undefined) && (statusShow.colorful_printers !== undefined);
		}
		case Show.copy: {
			return (statusShow.scanner !== undefined) && (
				(statusShow.bw_printers !== undefined) || 
				(statusShow.colorful_printers !== undefined)
			);
		}
		case Show.all: {
			return (
				statusShow.scanner !== undefined || 
				statusShow.bw_printers !== undefined || 
				statusShow.colorful_printers !== undefined
			);
		}
	}
}

export function getPrintTypeOfMapPoint(point: MapPoint) {
	if (!point.is_whitelabel) {
		return PrintType.automatic;
	}
	return PrintType.ab;
}

export function getShowByColor(color: 'bw' | 'colorful', show: Show):
	Show.colorfulPrinters |
	Show.colorfulCopy |
	Show.bwPrinters |
	Show.bwCopy
{
	if (color === 'bw') {
		if (show === Show.copy) {
			return Show.bwCopy;
		}
		return Show.bwPrinters;
	}
	if (show === Show.copy) {
		return Show.colorfulCopy;
	}
	return Show.colorfulPrinters;
}

export function getFeatures(
	point: MapPoint,
	show: Show,
	index: number,
) : MapFeatures {
	const present = 'islands#StretchyIcon';
	const color = isActiveByShow(show, point.status_show) ? '#4a944a' : '#D7D9DD';
	return {
		type: 'Feature',
		id: `point_${index}`,
		geometry: {
			type: 'Point',
			coordinates: [point.x_map_position, point.y_map_position],
		},
		options: {
			preset: present,
			iconColor: color,
			autoPanCheckZoomRange: true,
		},
	};
}

export function filterFeatures(points: MapPoint[], show: Show, printType: PrintType) {
	const features: MapFeatures[] = [];
	for (let i = 0; i < points.length; i++) {
		if (
			isExistByShow(show, points[i].status_show) &&
			(printType === PrintType.all || printType === getPrintTypeOfMapPoint(points[i]))
		) {
			features.push(getFeatures(points[i], show, i));
		}
	}
	return features;
}


export function isShowWithColor(show: Show) {
	return [Show.bwPrinters, Show.colorfulPrinters, Show.bwCopy, Show.colorfulCopy].includes(show);
}
