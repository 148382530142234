import { FC, useContext } from 'react';
import { QRContext } from 'context/qr-context';
import block from 'utils/bem-css-module';

import style from './map.scss';

const b = block(style);


export const MapScanQr: FC = () => {

	const setIsQrOpen = useContext(QRContext)[1];

	return <div className={b('scan-qr-icon')} onClick={() => setIsQrOpen(true)} />;
};
