import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './radio.scss';

const b = block(style);

type Props = {
	checked: boolean;
	name: string;
	id: string;
	disabled?: boolean;
}

export const Radio: FC<Props & HTMLAttributes<HTMLInputElement>> = ({
	checked,
	name,
	id,
	disabled = false,
	children,
	...rest
}) => {
	return(
		<p className={b({ disabled })}>
			<input
				id={id}
				type='radio'
				name={name}
				checked={checked}
				{...rest}
			/>
			<label htmlFor={id}>{children}</label>
		</p>
	);
};
