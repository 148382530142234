import { createContext, FC, HTMLAttributes, useState } from 'react';

import { MobileQrBlockFullScreen } from 'components/MobileQrBlock';

type Props = [boolean, (isOpen: boolean) => void];

export const QRContext = createContext<Props>(null!);

export const QRProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<QRContext.Provider value={[
			isOpen,
			setIsOpen,
		]}>
			{children}
			<MobileQrBlockFullScreen isQRReaderOpen={isOpen} onBack={() => setIsOpen(false)} />
		</QRContext.Provider>
	);
};
