import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GeolocationControl, GeoObject, Map, ObjectManager, SearchControl, ZoomControl } from '@pbe/react-yandex-maps';
import { getPoint } from 'api/api';
import block from 'utils/bem-css-module';
import { filterFeatures } from 'utils/map';

import { MapFilter } from 'components/MapFilter';

import { Coords } from 'types/common';
import { MapPoint, PrintType, Show } from 'types/map';
import { Point } from 'types/point';
import { State } from 'types/state';

import style from './map.scss';

const b = block(style);

const HEADER_HEIGHT = '64px';
const MAP_HEIGHT = `calc(100vh - ${HEADER_HEIGHT})`;

type Props  = {
    show: Show;
    onLoad?: () => void;
	onFilterSave: (save: Show, printType: PrintType) => void;
    setPoint: (point: Point | undefined) => void;
    setMapPoint: (point: MapPoint | undefined) => void;
	setIsMapModalOpen: (isMapModalOpen: boolean) => void;
	defaultCoords?: Coords;
	printType: PrintType;
}

export const YandexMap: FC<Props> = ({
	show,
	onLoad,
	onFilterSave,
	setPoint,
	setIsMapModalOpen,
	setMapPoint,
	defaultCoords,
	printType,
}) => {

	const [isLoading, setIsLoading] = useState(true);

	const { coords, points } = useSelector(({ user, points }: State) => ({
		coords: defaultCoords || user?.coords || window.COORDS,
		points,
	}));

	const getPosition = () => {
		setIsLoading(false);
		if (defaultCoords) {
			onLoad?.();
			return;
		}
		onLoad?.();
	};

	const onClick = async (e) => {
		if (!points) {
			return;
		}
		const id = e.get('objectId');
		const index = Number(id.split('_').pop());
		//случай клика на балун
		if (!id.startsWith('point_')) {
			return;
		}
		const selectedMapPoint = points[index];
		setMapPoint(selectedMapPoint);
		getPoint(selectedMapPoint.pk).then(setPoint);
		setIsMapModalOpen(true);
	};

	const features = useMemo(() => {
		if (!points || !show) {
			return [];
		}
		return filterFeatures(points, show, printType);
	}, [points, show, printType]);

	return (
		<div 
			className={b({ loading: isLoading }).mix('theme--light')}
			style={{ height: MAP_HEIGHT }}
		>
			{coords && (
				<Map
					onLoad={getPosition}
					width='inherit'
					height={MAP_HEIGHT}
					defaultState={{
						center: [coords.latitude, coords.longitude],
						zoom: 12,
						controls: [],
					}}
				>
					<MapFilter show={show} printType={PrintType.all} onSave={onFilterSave} />
					<ZoomControl options={{ size: 'small', position: { top: 12 + 52 + 16, right: 12 } }} width={52} height={52} />
					<GeolocationControl options={{ position: { top: 198, right: 12 } }} />
					<SearchControl options={{ kind: 'metro' }} />
					<GeoObject
						geometry={{
							type: 'Point',
							coordinates: [coords.latitude, coords.longitude],
						}}
						options={{
							preset: 'islands#redCircleIcon',
							draggable: true,
						}}
					/>
					<ObjectManager
						options={{
							clusterize: true,
							gridSize: 32,
							clusterBalloonContentLayout: 'cluster#balloonCarousel',
							clusterBalloonContentLayoutHeight: '100%',
						}}
						objects={{
							preset: 'islands#blueDotIcon',
						}}
						clusters={{
							clusterIconLayout: 'default#pieChart',
							clusterIconPieChartRadius: 25,
							clusterIconPieChartCoreRadius: 10,
							clusterIconPieChartStrokeWidth: 3,
							clusterBalloonContentLayout: 'cluster#balloonCarousel',
						}}
						features={features}
						onClick={onClick}
					/>
				</Map>
			)}
		</div>
	);
};
