import { FC, HTMLAttributes, useEffect, useState } from 'react';
import block from 'utils/bem-css-module';

import { Button } from 'components/Button';
import { CodeInput } from 'components/CodeInput';
import { Paragraph } from 'components/Paragraph';
import { QrCodeReader } from 'components/QrCodeReader';

import style from './mobile-qr-block.scss';


const b = block(style);

type Props = {
    isQRReaderOpen: boolean;
    onBack: () => void;
}

export const MobileQrBlock: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	isQRReaderOpen,
	onBack,
	className,
	...rest
}) => {
	const [isQrMode, setIsQrMode] = useState(true);

	const onCodeInputFinish = (code: string) => {
		window.location.href = `/?default_point_id=${code}`;
	};

	useEffect(() => {
		if (!isQRReaderOpen) {
			setIsQrMode(true);
		}
	}, [isQRReaderOpen]);

	return (
		<div className={b({ open: isQRReaderOpen }).mix(`${className || ''} theme--dark`)} {...rest}>
			<Paragraph size='m'>Код нужно получить <br/>у сотрудника отделения Сбер</Paragraph>
			{isQrMode ?
				<QrCodeReader className={b('qr-reader')} isOpen={isQRReaderOpen} /> :
				<CodeInput className={b('qr-reader')} onFinish={onCodeInputFinish} />
			}
			<div className={b('buttons')}>
				<Button
					size='m'
					buttonType='info'
					className={b('button', { type: 'digital' })}
					onClick={() => setIsQrMode(mode => !mode)}
				>
					{isQrMode ? 'Ввести код вручную' : 'Отсканировать QR'}
				</Button>
				<Button
					size='m'
					buttonType='default'
					className={b('button')}
					onClick={onBack}
				>
                	Назад
				</Button>
			</div>
		</div>
	);
};
